import { createGlobalStyle, css } from 'styled-components';
import LoveloBlack from './assets/fonts/lovelo/fonts/Lovelo_Black.ttf';
import LoveloLineLight from './assets/fonts/lovelo-line/lovelo-line-light.ttf';
import RobotoCondensed from './assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf';
import RobotoRegular from './assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoLightItalic from './assets/fonts/Roboto/Roboto-LightItalic.ttf';
import RobotoMedium from './assets/fonts/Roboto/Roboto-Medium.ttf';
import RobotoBlack from './assets/fonts/Roboto/Roboto-Black.ttf';
import RobotoBold from './assets/fonts/Roboto/Roboto-Bold.ttf';
import OpenSansRegular from './assets/fonts/Open_Sans/OpenSans-Regular.ttf';
import OpenSansLightItalic from './assets/fonts/Open_Sans/OpenSans-LightItalic.ttf';
import OpenSansSemiBoldItalic from './assets/fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf';
import OpenSansSemiBold from './assets/fonts/Open_Sans/OpenSans-SemiBold.ttf';
import OpenSansBold from './assets/fonts/Open_Sans/OpenSans-Bold.ttf';
import OpenSansExtraBold from './assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf';
import Muli from './assets/fonts/Muli/Muli-Regular.ttf';
// import AvenirBlack from './assets/fonts/AvenirLTStd-Black.otf';

export const colors = {
	lightGrey: '#abbcd2',
	bgGrey: '#ececec',
	hrGrey: '#DFDFDF',
	orange: '#fd4326',
	white: '#ffffff',
	whiteSmoke: '#fbfbfb',
	whiteGrey: '#f9f9f9',
	blackTitle: '#23252c',
	greyItalicTitle: '#7a8085',
	darkGrey: '#535353',
	blueGrey: '#667e9d',
	blueDark: '#365277',
	blueText: '#36b1e5',
	grey: '#e8e8e8',
	greyScale: '#e1e4e9',
	red: '#ed5565',
	redBright: '#f73f0e',
	borderBottom: '#536f94',
	brownText: '#434343',
};
export const fontSizes = {
	h1: '72px',
	h2: '48px',
	h3: '26px',
	h4: '24px',
	h5: '17px',
	p: '15px',
	tiny: '13px',
	topCoverTitle: '56px',
	topCoverSubtitle: '46px',
};

export const fonts = {
	loveloBlack: "'Lovelo Black', sans-serif", // eslint-disable-line
	robotoCondensed: "'Roboto Condensed', sans-serif", // eslint-disable-line
	robotoRegular: "'Roboto', sans-serif", // eslint-disable-line
	robotoMedium: "'Roboto Medium', sans-serif", // eslint-disable-line
	robotoBlack: "'Roboto Black', sans-serif", // eslint-disable-line
	robotoBold: "'Roboto Bold', sans-serif", // eslint-disable-line
	robotoLightItalic: '"Roboto - Light Italic", sans-serif', // eslint-disable-line
	openSans: "'Open Sans', sans-serif", // eslint-disable-line
	openSansSembiBoldItalic: '"Open Sans - Semibold Italic", sans-serif', // eslint-disable-line
	openSansLightItalic: '"Open Sans - Light Italic", sans-serif', // eslint-disable-line
	openSansSembiBold: '"Open Sans - Semibold", sans-serif', // eslint-disable-line
	openSansBold: '"Open Sans - Bold", sans-serif', // eslint-disable-line
	openSansExtraBold: '"Open Sans - Extra Bold", sans-serif', // eslint-disable-line
	muli: "'Muli', sans-serif", // eslint-disable-line
	loveloLineLight: 'LoveloLineLight',
};
export const breakpoints = {
	xxl: '(min-width: 1600px)',
	xl: '(min-width: 1366px)',
	over1600: '(min-width: 1600px)',
	maxXxl: '(max-width: 1600px)',
	maxXl: '(max-width: 1365px)',
	maxLg: '(max-width: 1199px)',
	maxMd: '(max-width: 991px)',
	maxSm: '(max-width: 767px)',
	maxXs: '(max-width: 479px)',
	minLg: '(min-width: 1200px)',
	minMd: '(min-width: 992px)',
	minSm: '(min-width: 768px)',
	minXs: '(min-width: 480px)',
	laptopM: '(max-width: 1681px)',
	laptop: '(max-width: 1281px)',
	minLaptop: '(min-width: 1282px)',
	laptopS: '(max-width: 1025px)',
	minLaptopS: '(min-width: 1026px)',
	tablet: '(max-width: 900px)',
	smallTablet: '(min-width: 481px) and (max-width: 767px)',
	phone: '(min-width: 320px) and (max-width: 480px)',
};
export const media = Object.keys(breakpoints).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media ${breakpoints[label]} {
			${css(...args)}
		}
	`;
	return acc;
}, {});

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Lovelo Black';
    src: url('${LoveloBlack}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Roboto Condensed';
    src: url('${RobotoCondensed}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Roboto';
    src: url('${RobotoRegular}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Roboto Medium';
    src: url('${RobotoMedium}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Roboto Black';
    src: url('${RobotoBlack}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Roboto Bold';
    src: url('${RobotoBold}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Roboto - Light Italic';
    src: url('${RobotoLightItalic}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Open Sans';
    src: url('${OpenSansRegular}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Open Sans - Light Italic';
    src: url('${OpenSansLightItalic}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Open Sans - Semibold Italic';
    src: url('${OpenSansSemiBoldItalic}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Open Sans - Semibold';
    src: url('${OpenSansSemiBold}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Open Sans - Extra Bold';
    src: url('${OpenSansExtraBold}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Open Sans - Bold';
    src: url('${OpenSansBold}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Muli';
    src: url('${Muli}')  format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'LoveloLineLight';
    src: url('${LoveloLineLight}')  format('truetype'); /* Safari, Android, iOS */
  }
  body {
	 ${media.xl`
			&.show-preloader {
					overflow: hidden;
					.preloader {
							display: flex !important;
					}
					&.hiding-preloader {
						.preloader {
							opacity: 0;
						}
					}
			}
	 `}
		&.disable-scroll {
			overflow: hidden;
		}
  }
  ul,
  figure {
  	margin: 0
  }
  li {
  	list-style: none;
  }
  .hidden-xs {
  	${media.maxXs`
  		display: none!important;
  	`}
  }
  .visible-xs {
  	display: none;
		${media.maxXs`display: block!important;`}
  }
  .hidden-sm {
  	${media.maxSm`display: none!important;`}
  }
  .visible-sm{
  	display: none !important;
		${media.maxSm`display: block!important;`}
  }
  .visible-md{
  	display: none !important;
		${media.maxMd`display: block!important;`}
  }
  body.hiding-preloader {
  	#line2 {
		stroke-dasharray: 106;
		animation: line-2 .8s linear forwards;
	}
		@keyframes line-2 {
			from {
				stroke-dashoffset: 106;
		}
			to {
				stroke-dashoffset: 0;
			}
		}
		#line3 {
			stroke-dasharray: 190;
			stroke-dashoffset: 190;
			animation: line-3 .8s linear forwards;
			animation-delay: .8s;
		}
		@keyframes line-3 {
			from {
				stroke-dashoffset: 190;
		}
			to {
				stroke-dashoffset: 0;
			}
		}
		#line4,
		#line5 {
			opacity: 0;
			animation: line-4 .1s linear forwards;
			animation-delay: 1.6s;
		}
		@keyframes line-4 {
			100% {opacity: 1;}
		}
  }
  *:focus {
    outline: none;
	}
`;

export default GlobalStyle;
