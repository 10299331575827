import React from 'react';
import styled from 'styled-components';

import PulseCircle from '../_common/PulseCircle';
import { colors, fonts, fontSizes, media } from '../../globalStyles';

export const StyledScrollForMore = styled.div`
	position: absolute;
	bottom: 96px;
	left: 0;
	right: 0;
	display: block;
	text-align: center;
	z-index: 1;
	.circles-col {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 33px;
		margin-bottom: 27px;
		span {
			width: 8px;
			height: 8px;
			border-radius: 100%;
			border: 1px solid ${colors.lightGrey};
			margin-bottom: 4px;
		}
	}
	.scroll-text {
		font-size: ${fontSizes.h4};
		color: ${colors.lightGrey};
		font-family: ${fonts.robotoCondensed};
	}
	${media.maxXs`
		bottom: 40px;
	`}
`;

const ScrollForMore = () => (
	<StyledScrollForMore>
		<PulseCircle />
		<div className="circles-col">
			<span />
			<span />
			<span />
		</div>
		<div className="scroll-text">scroll for more</div>
	</StyledScrollForMore>
);

export default ScrollForMore;
