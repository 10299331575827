import React from 'react';
import PropTypes from 'prop-types';
import P from '../../_common/P';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { fonts, colors, media } from '../../../globalStyles';
import CountUp from 'react-countup';

const StyledFrameworkItem = styled.div`
	position: relative;
	width: 24%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 74px 0;
	max-width: 270px;
	${media.maxSm`
		width: 100%;
		max-width: none;
		&:last-child {
			.item-text {
				&:after {
					display: none;
				}
			}
		}
	`}
	.counter-number {
		width: 100%;
		text-align: left;
		top: 14px;
		left: 0;
		position: absolute;
		color: ${colors.whiteGrey};
		font-family: ${fonts.loveloBlack};
		font-size: 120px;
		font-weight: 400;
		display: block;
		line-height: 120px;
		white-space: nowrap;
		${media.tablet`
			font-size: 90px;
		`}
		${media.maxSm`
			width: auto;
			font-size: 120px;
			left: 50%;
			transform: translateX(-50%);
		`}
	}
	.item-text {
		position: relative;
		z-index: 1;
		color: ${colors.blackTitle};
		font-family: ${fonts.loveloBlack};
		font-size: 26px;
		font-weight: 400;
		width: 100%;
		text-align: center;
		&:after {
			position: absolute;
			width: 60%;
			content: '';
			max-width: 120px;
			height: 1px;
			background: #fd4326;
			left: 0;
			bottom: -60px;
			${media.maxSm`
				left: 50%;
				bottom: -45px;
				transform: translateX(-50%);
			`}
		}
		${media.tablet`
			font-size:20px;
		`}
		${media.maxSm`
			font-size:26px;
		`}
	}
`;

const CountItem = ({ title, count }) => (
	<StyledFrameworkItem>
		<P className="item-text">{title}</P>
		<CountUp duration={5} start={0} end={count}>
			{({ countUpRef, start }) => (
				<div>
					<span className="counter-number" ref={countUpRef} />
					<ScrollAnimation
						animateIn="reset timer"
						duration={0.01}
						initiallyVisible={true}
						afterAnimatedIn={function afterAnimatedIn() {
							start();
						}}
					/>
				</div>
			)}
		</CountUp>
	</StyledFrameworkItem>
);

CountItem.propTypes = {
	title: PropTypes.string,
	count: PropTypes.string,
};
export default CountItem;
