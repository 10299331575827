import React from 'react';
import PropTypes from 'prop-types';
import TextCenter from './TextCenter';
import StyledButtonLink from './ButtonLink';
import gtag from '../../gtag';

const CenterButton = ({ linkTo, children, clear, gaLabel }) => {
	function onClick() {
		gtag('Click', gaLabel);
	}
	return (
		<TextCenter>
			<StyledButtonLink onClick={onClick} clear={clear} arrow to={linkTo}>
				{children || 'Discover More'}
			</StyledButtonLink>
		</TextCenter>
	);
};
CenterButton.propTypes = {
	linkTo: PropTypes.string,
	gaLabel: PropTypes.string.isRequired,
	children: PropTypes.any,
	clear: PropTypes.bool,
};

export default CenterButton;
