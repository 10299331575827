import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import commonButtonStyles from './commonStyles/commonButtonStyles';

const StyledButtonLink = styled(Link)`
	${commonButtonStyles}
`;

StyledButtonLink.propTypes = {
	arrow: PropTypes.bool,
	clear: PropTypes.bool,
};

export default StyledButtonLink;
