import styled from 'styled-components';
import { fontSizes } from '../../globalStyles';
import commonHeadingStyles from './commonStyles/commonHeadingStyles';

const H1 = styled.h1`
	font-size: ${fontSizes.h1};
	${commonHeadingStyles};
`;

export default H1;
