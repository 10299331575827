import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import H2 from '../../../components/_common/H2';
import CountItem from './CountItem';
import CenterButton from './../../../components/_common/CenterButton';

import { performanceData } from './performanceData';
import { media } from '../../../globalStyles';

const StyledPerformance = styled.div`
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 136px;
	${media.maxSm`
		margin-bottom: 40px;
	`}
	${H2} {
		margin-top: 50px;
	}
`;

const Performance = () => (
	<ContentSection
		paddingBottom={0}
		title={'Fast, Fully Customized, and Fantastic Performance!'}
		subtitle={
			'<b>PixelTeh</b> delivers cross-platform app technology like no other. ' +
			'Unless you want to lose half (or more than half) of your customers, ' +
			'you need to build a hybrid app. You’ll reach a broader audience' +
			' and produce an app that is faster and packed with the most up-to-date' +
			' features. There’s a good reason why clients have been coming to us ' +
			'for nearly 10 years. Experience the <b>PixelTeh</b> difference. We invite ' +
			'you to schedule a consultation if you need a multi-platform app for' +
			' your business.'
		}
		seoTitle={'h2'}
		seoSubTitle={'p'}
	>
		<StyledPerformance>
			{/*<Container>*/}
			{performanceData.map((item, index) => (
				<CountItem key={index} title={item.title} count={item.count} />
			))}
		</StyledPerformance>
		<CenterButton
			linkTo="/contact#contact-section"
			gaLabel={'technologies-crossplatform Start A Project with us'}
		>
			Start A Project with us
		</CenterButton>
		{/*</Container>*/}
	</ContentSection>
);

export default Performance;
