import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

export const StyledScrollAnimation = styled(ScrollAnimation)`
	@keyframes zoomInUp {
		from {
			opacity: 0;
			transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
			animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}

		60% {
			opacity: 1;
			transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
			animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		}
	}

	&.zoomInUp {
		animation-name: zoomInUp;
	}

	@keyframes zoomOutUp {
		40% {
			opacity: 1;
			transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
			animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}

		to {
			opacity: 0;
			transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
			transform-origin: center bottom;
			animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		}
	}

	&.zoomOutUp {
		animation-name: zoomOutUp;
	}
	@keyframes fadeInDown {
		from {
			opacity: 0;
			transform: translate3d(0, -100%, 0);
		}

		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	&.fadeInDown {
		animation-name: fadeInDown;
	}
	@keyframes fadeInUp {
		from {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}

		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	&.fadeInUp {
		animation-name: fadeInUp;
	}
	@keyframes fadeOutUp {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
			transform: translate3d(0, -100%, 0);
		}
	}

	&.fadeOutUp {
		animation-name: fadeOutUp;
	}
	@keyframes fadeOutDown {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
			transform: translate3d(0, -100%, 0);
		}
	}

	&.fadeOutDown {
		animation-name: fadeOutDown;
	}
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	&.fadeIn {
		animation-name: fadeIn;
	}
	@keyframes fadeOut {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}

	&.fadeOut {
		animation-name: fadeOut;
	}
	@keyframes zoomIn {
		from {
			opacity: 0;
			transform: scale3d(0.3, 0.3, 0.3);
		}

		50% {
			opacity: 1;
		}
	}

	&.zoomIn {
		animation-name: zoomIn;
	}
	@keyframes zoomOut {
		from {
			opacity: 1;
		}

		50% {
			opacity: 0;
			transform: scale3d(0.3, 0.3, 0.3);
		}

		to {
			opacity: 0;
		}
	}

	&.zoomOut {
		animation-name: zoomOut;
	}
	@keyframes fadeInLeft {
		from {
			opacity: 0;
			transform: translate3d(-35%, 0, 0);
		}

		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	&.fadeInLeft {
		animation-name: fadeInLeft;
	}
	@keyframes fadeInRight {
		from {
			opacity: 0;
			transform: translate3d(35%, 0, 0);
		}

		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	&.fadeInRight {
		animation-name: fadeInRight;
	}
	@keyframes fadeOutLeft {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
			transform: translate3d(-35%, 0, 0);
		}
	}

	&.fadeOutLeft {
		animation-name: fadeOutLeft;
	}
	@keyframes fadeOutRight {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
			transform: translate3d(35%, 0, 0);
		}
	}

	&.fadeOutRight {
		animation-name: fadeOutRight;
	}
	@keyframes flipUp {
		from {
			opacity: 0;
			transform: rotateX(-90deg);
		}

		to {
			opacity: 1;
			transform: rotateX(0deg);
		}
	}

	&.flipUp {
		animation-name: flipUp;
	}
	@keyframes flipDown {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
			transform: rotateX(90deg);
		}
	}

	&.flipDow {
		animation-name: flipDow;
	}
`;

const AnimatedOnScroll = ({ children, animateIn, animateOut }) => (
	<StyledScrollAnimation
		duration={0.4}
		animateIn={animateIn}
		animateOut={animateOut}
	>
		{children}
	</StyledScrollAnimation>
);

AnimatedOnScroll.defaultProps = {
	animateIn: 'fadeInDown',
	animateOut: 'fadeOutUp',
};

AnimatedOnScroll.propTypes = {
	children: PropTypes.any,
	animateIn: PropTypes.string,
	animateOut: PropTypes.string,
};
export default AnimatedOnScroll;
