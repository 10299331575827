import React from 'react';
import styled from 'styled-components';
import TypographyItem from './TypographyItem';
import Container from './../../../components/_common/Container';
import AnimatedOnScroll from './../../../components/_common/AnimatedOnScroll';

import apple from './../../../assets/img/apple-img.png';
import hybrid from './../../../assets/img/hybrid-img.png';
import android from './../../../assets/img/android-img.png';
import html from './../../../assets/img/html-img.png';
import H2 from '../../_common/H2';
import P from '../../_common/P';

import { colors, fonts, media } from '../../../globalStyles';

const StyledTypographyCrossplatform = styled.div`
	margin: 126px auto 0;
	padding-bottom: 100px;
	.container {
		max-width: 1000px;
	}
	.typography-block-cover {
		display: flex;
		position: relative;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		${media.maxSm`
			align-items: center;
		`}
	}
	.text-cover {
		.container {
			max-width: 1350px;
		}
	}
	${H2} {
		margin-top: 80px;
		text-align: center;
		color: ${colors.blackTitle};
		font-family: ${fonts.robotoMedium};
		font-size: 34px;
		line-height: 32px;
		padding-bottom: 72px;
		${media.maxSm`
			font-size: 18px;	
		`}
	}
	${P} {
		color: ${colors.blackTitle};
		font-family: ${fonts.openSans};
		font-size: 15px;
		line-height: 30px;
	}
`;
const TypographyCrossplatform = () => (
	<StyledTypographyCrossplatform>
		<Container>
			<div className="typography-block-cover ">
				<TypographyItem
					img={android}
					title={'ANDROID'}
					animatedIn={'Right'}
					animatedOut={'Left'}
					addLine={true}
					lineHorisontal={true}
					reverseLine={true}
					alt={'android phone symbol'}
				/>
				<TypographyItem
					img={hybrid}
					reverse={true}
					roundBg={true}
					title={'HYBRID'}
					animatedIn={'Up'}
					animatedOut={'Down'}
					altTe={'letter h for hybrid'}
				/>
				<TypographyItem
					img={apple}
					title={'IOS'}
					animatedIn={'Left'}
					animatedOut={'Right'}
					addLine={true}
					lineHorisontal={true}
					alt={'apple icon for ios'}
				/>
				<TypographyItem
					img={html}
					addLine={true}
					title={'WINDOWS'}
					animatedIn={'Up'}
					animatedOut={'Up'}
					alt={'windows icon'}
				/>

				<AnimatedOnScroll animateIn={'fadeInRight'} animateOut={'fadeOutLeft'}>
					<div className="line-direction line-direction_before" />
				</AnimatedOnScroll>
				<AnimatedOnScroll animateIn={'fadeInLeft'} animateOut={'fadeOutRight'}>
					<div className="line-direction line-direction_second" />
				</AnimatedOnScroll>
			</div>
		</Container>
		<div className="text-cover">
			<Container>
				<H2>Multi-Platform App Development for All Operating Systems</H2>
				<P>
					What type of phone do you use? Android, iPhone, Windows Mobile, Google
					Pixel, or another Smartphone? Now, think about your customers. Not all
					of them use the same type of phone. When you want to launch a new app,
					it’s vital to create one that is built using cross-platform
					programming (also known as hybrid or multi-platform development). The
					expert programmers at our cross-platform mobile app development
					company are well-versed in coding for iOS and all Smartphone operating
					systems. So, any app we create works just as well on one phone as it
					does on another.
				</P>
				<br />
				<br />
				<P>
					Using an agile development methodology, we create these hybrid
					applications that will meet the needs of your users and business.
					These are premium-quality apps that are rich in features, always
					dependable, and use the latest technology to encourage user
					engagement. In addition, the same end-user experience can be seen on
					desktop platforms as it is on mobile devices. We achieve this by using
					advanced programming that is “readable” by all platforms. Some
					less-experienced coders may have training in a few languages. But we
					know them all. With more than 50 developers, project managers, and
					staff at our company, we know how to achieve your app development
					goals over multiple platforms.
				</P>
			</Container>
		</div>
	</StyledTypographyCrossplatform>
);

export default TypographyCrossplatform;
