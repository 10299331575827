import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
	tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	noGutters: PropTypes.bool,
	className: PropTypes.string,
	cssModule: PropTypes.object,
	form: PropTypes.bool,
};

const defaultProps = {
	tag: 'div',
};

const Row = props => {
	const { className, noGutters, tag: Tag, form, ...attributes } = props;

	const classes = classNames(
		className,
		noGutters ? 'no-gutters' : null,
		form ? 'form-row' : 'row'
	);

	return <Tag {...attributes} className={classes} />;
};

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
