import React, { Fragment } from 'react';
import Typist from 'react-typist';
import H2 from '../_common/H2';

const phrases = ['Need a Free Quote?', 'Send Us the Project Brief'];

class TypingText extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentLine: 0,
		};
	}

	handleTypingDone = () => {
		this.setState(({ currentLine }) => ({
			currentLine: currentLine === phrases.length - 1 ? 0 : currentLine + 1,
		}));
	};

	render() {
		const { currentLine } = this.state;

		return (
			<Fragment>
				{phrases.map(
					(phrase, index) =>
						index === currentLine && (
							<Typist
								key={phrase}
								stdTypingDelay={45}
								avgTypingDelay={90}
								onTypingDone={this.handleTypingDone}
							>
								<H2>{phrase}</H2>

								<Typist.Backspace count={8} delay={3000} />
							</Typist>
						)
				)}
			</Fragment>
		);
	}
}

export default TypingText;
