export const home = {
	title: 'Mobile App & Web Development Company | PixelTeh',
	keywords:
		'mobile app development company, web development company, vr development companies, ar development companies, ai development companies, blockchain programming company, cryptocurrency development company, machine learning development',
	description:
		'Take a step into the future of immersive technology. Pixelteh is a mobile app and web development company that makes the impossible possible.',
};

export const technologies = {
	title: 'Immersive Technologies | PixelTeh – Web & Mobile Development',
	keywords:
		'immersive technology, virtual reality company, augmented reality company, full stack development services, mobile app programming company',
	description:
		'Take your business idea into the future with immersive technology. PixelTeh is a VR, AR, AI, and mobile app development company that achieves your goals.',
};
export const fullStack = {
	title: 'Full-Stack Development | Pixelteh',
	keywords:
		'full-stack development, full-stack developers, full-stack web developer, full-stack programming, full-stack engineer',
	description:
		'From idea to completion, you can trust PixelTeh for comprehensive full-stack development. We customize your website or mobile app to suit your needs.',
};
export const blockchain = {
	title: 'Blockchain & Cryptocurrency Development | PixelTeh',
	keywords:
		'blockchain development, cryptocurrency development, ico development, smart contract development, dapp development, wallets development',
	description:
		'Change and update the way you purchase and trade by using PixelTeh for cryptocurrency and blockchain development. We help you use a better way to buy and sell.',
};
export const machineLearining = {
	title: 'AI & Machine Learning Company | PixelTeh',
	keywords:
		'machine learning company, artificial intelligence development company, machine learning developers, ai developer, artificial intelligence programming, ml programming, ai programming',
	description:
		'Teach your computer, app, or system to learn. PixelTeh is a machine learning company that specialize in ML and artificial intelligence programming for many industries.',
};
export const crossPlatform = {
	title: 'Cross-Platform App Development | PixelTeh',
	keywords:
		'cross-platform development, hybrid app development, native app development, cross platform app development, cross platform mobile development, cross platform mobile app development, app development company, multi-platform app development, hybrid app development company, cross platform mobile app development',
	description:
		'Access your app on any operating system by using PixelTeh for cross-platform development. We are expert developers of hybrid and native apps.',
};
export const android = {
	title: 'Android App Development | PixelTeh',
	keywords:
		'android app development services, android developer, android app development company, android application development',
	description:
		'Create an app that captures an audience by relying on PixelTeh for android app development. We develop fully customized android applications.',
};
export const ios = {
	title: 'iOS App Development | PixelTeh',
	keywords:
		'ios app development, iphone app development, custom ios apps, ios app developers, ios application development company',
	description:
		'Simplify technology by depending on PixelTeh for iOS app development. We create customized iPhone applications that power and promote your business.',
};
export const portfolio = {
	title: 'Portfolio & Clients | PixelTeh',
	keywords: 'pixelteh clients, pixelteh portfolio',
	description:
		'Find out more about our work and our clients. The PixelTeh team is proud to provide web and mobile app development, AI, AR, VR, and blockchain development.',
};
export const team = {
	title: 'About Us | PixelTeh',
	keywords:
		'web developers, web development, mobile development, web programmers',
	description:
		'Build a website that is engaging, functional, and completely customized. As web and mobile app developers, we create digital experiences that power your business.',
};
export const contact = {
	title: 'Contact Us | PixelTeh',
	keywords: 'contact pixelteh',
	description:
		'Let’s make something great together. PixelTeh ensures the best service possible with every project we undertake. We’d love to work with you!',
};
export const blog = {
	title: 'Blog | PixelTeh',
	keywords: 'blog pixelteh',
	description:
		'Let’s make something great together. PixelTeh ensures the best service possible with every project we undertake. We’d love to work with you!',
};
