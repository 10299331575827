import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../globalStyles';

const StyledNavbarNav = styled.ul`
	display: flex;
	justify-content: center;
	list-style: none;
	width: 100%;
	padding-left: 0;
	margin: 0;
	${media.maxSm`
		flex-direction: column;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #212c3c;
		z-index: -1;
		opacity: 0;
		transition: opacity 0.3s;
		pointer-events: none;
		&.open{
			opacity: 1
			z-index: 100;
			pointer-events: all;
			overflow: auto;
		}
	`}
	@media(max-height: 500px) {
		padding: 50px 0;
		justify-content: flex-start;
	}
`;

const StyledNavbarNavWrapper = styled.div`
	display: flex;
	flex-basis: auto;
	flex-grow: 1;
	align-items: center;
`;

const NavbarNav = ({ children, isOpen }) => (
	<StyledNavbarNavWrapper>
		<StyledNavbarNav className={isOpen ? 'open' : ''}>
			{children}
		</StyledNavbarNav>
	</StyledNavbarNavWrapper>
);

NavbarNav.propTypes = {
	children: PropTypes.any,
	isOpen: PropTypes.bool,
};

export default NavbarNav;
