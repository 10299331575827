import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Container from '../_common/Container';

import sectionBorderBg from '../../assets/img/border-alt.png';
import SectionHeader from './SectionHeader';
import SectionHeaderBig from './SectionHeaderBig';
import { colors, media } from '../../globalStyles';

const StyledContentSection = styled.section`
	position: relative;
	padding-bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
	.content-section-top-border {
		background-image: url(${sectionBorderBg});
		background-repeat: repeat-x;
		height: 32px;
	}
	.container{
		max-width: 1511px;
	}
	${media.maxSm`
		padding-bottom: ${({ paddingBottomSm }) => `${paddingBottomSm}px`};
	`}
	${({ bgColor }) => {
		if (bgColor === 'white') {
			return css`
				background-color: ${colors.white};
			`;
		}
		if (bgColor === 'whiteGrey') {
			return css`
				background-color: ${colors.whiteGrey};
			`;
		}
		if (bgColor === 'grey') {
			return css`
				background-color: ${colors.bgGrey};
			`;
		}
		if (bgColor === 'blueDark') {
			return css`
				background-color: ${colors.blueDark};
			`;
		}
	}}
	${({ disablePadding }) => {
		if (disablePadding) {
			return css`
				.container-fluid {
					padding: 0;
				}
				.container {
					padding: 0;
				}
			`;
		}
	}}
	${({ hiddenSm }) =>
		hiddenSm &&
		css`
			${media.maxSm`
			display: none;
		`}
		`}
	${({ customStyles }) => customStyles && customStyles}
`;

const ContentSection = props => {
	const Header =
		props.headerType === 'normal' ? SectionHeader : SectionHeaderBig;
	return (
		<StyledContentSection
			customStyles={props.customStyles}
			hiddenSm={props.hiddenSm}
			paddingBottom={props.paddingBottom}
			paddingBottomSm={props.paddingBottomSm}
			bgColor={props.bgColor}
			disablePadding={props.disablePadding}
		>
			<div className={!props.noTopBorder ? 'content-section-top-border' : ''} />
			<Container fluid={props.fluid}>
				{!props.noHeader && (
					<Header
						title={props.title}
						subtitle={props.subtitle}
						bottomLine={props.bottomLine}
						seoTitle={props.seoTitle}
						seoSubTitle={props.seoSubTitle}
					/>
				)}
				{props.children}
			</Container>
		</StyledContentSection>
	);
};
ContentSection.propTypes = {
	headerType: PropTypes.oneOf(['big', 'normal']),
	noHeader: PropTypes.bool,
	disablePadding: PropTypes.bool,
	paddingBottom: PropTypes.number,
	paddingBottomSm: PropTypes.number,
	bgColor: PropTypes.string,
	title: PropTypes.any,
	subtitle: PropTypes.any,
	children: PropTypes.any,
	fluid: PropTypes.bool,
	hiddenSm: PropTypes.bool,
	noTopBorder: PropTypes.bool,
	bottomLine: PropTypes.bool,
	customStyles: PropTypes.any,
	seoTitle: PropTypes.string,
	seoSubTitle: PropTypes.string,
};
ContentSection.defaultProps = {
	headerType: 'normal',
	bgColor: 'white',
	fluid: false,
	paddingBottom: 87,
	paddingBottomSm: 30,
	noTopBorder: false,
};
export default ContentSection;
