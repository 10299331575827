import React from 'react';
import styled from 'styled-components';
import ContentSection from '../../ContentSection';
import Container from '../../../components/_common/Container';
import FrameworkItem from './FrameworkItem';
import { FrameworksData } from './FrameworksData';
import gap from '../../../assets/img/phone-gap.png';
import ionic from '../../../assets/img/ionic-logo-alt.png';
import xamarin from '../../../assets/img/xamarin.png';
import kony from '../../../assets/img/kony.png';
import sencha from '../../../assets/img/senca.png';
import native from '../../../assets/img/react-native-logo-alt.png';

const FrameworkImages = [
	{
		icon: gap,
		alt: 'phone gap logo',
	},
	{
		icon: ionic,
		alt: 'ionic logo',
	},
	{
		icon: xamarin,
		alt: 'xamarin logo',
	},
	{
		icon: kony,
		alt: 'kony logo',
	},
	{
		icon: sencha,
		alt: 'sencha logo',
	},
	{
		icon: native,
		alt: 'react native logo',
	},
];

const StyledFrameworks = styled.div`
	max-width: 1510px;
	margin: auto;
	.container {
		padding-top: 100px;
		padding-bottom: 140px;
		max-width: 1555px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
`;

const Frameworks = () => (
	<StyledFrameworks>
		<ContentSection
			fluid
			paddingBottom={0}
			title={'Popular Cross-Platform App Development Frameworks'}
			subtitle="You’d never use antiquated tools to build a house or assemble
			a car. The result would be something that is poorly built. The same is
			true for mobile apps. Yesterday’s building blocks are inefficient when
			it comes to developing new applications for mobile or web. Today,
			<b>PixelTeh</b> uses the most advanced frameworks to ensure consistent
			value and high-performing cross-platform apps. We build beautiful
			hybrid apps that perform, impress, and even inspire. For a better
			experience and a great app, depend on our developers for custom
			app design, development, and deployment. We are proficient at
			customizing apps using any of the following popular frameworks:"
			seoTitle={'h2'}
			seoSubTitle={'p'}
		/>
		<Container>
			{FrameworksData.map((item, index) => (
				<FrameworkItem
					key={index}
					text={item.text}
					image={FrameworkImages[index]}
				/>
			))}
		</Container>
	</StyledFrameworks>
);

export default Frameworks;
