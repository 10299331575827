import React from 'react';
import styled from 'styled-components';

import Container from '../../_common/Container';
import DetailsItem from './DetailsItem';
import ContentSectionTopBorder from '../../_common/ContentSectionTopBorder';
import { fonts, colors, media } from '../../../globalStyles';

import H3 from '../../../components/_common/H3';
import P from '../../../components/_common/P';
import apple from '../../../assets/img/apple-blue.png';
import android from '../../../assets/img/android-blue.png';
import windows from '../../../assets/img/windows-blue.png';
import html5 from '../../../assets/img/html-blue.png';
import js from '../../../assets/img/js-blue.png';
import css3 from '../../../assets/img/html3-blue.png';
import question from '../../../assets/img/question-img.png';
import { Link } from 'gatsby';

const iconsFirst = [
	{
		icon: apple,
		alt: 'blue apple logo',
	},
	{
		icon: android,
		alt: 'blue android icon',
	},
	{
		icon: windows,
		alt: 'blue windows icon',
	},
];
const iconsSecond = [
	{
		icon: html5,
		alt: 'blue 5',
	},
	{
		icon: js,
		alt: 'blue JS',
	},
	{
		icon: css3,
		alt: 'blue css',
	},
];

const StyledWhatIsBetter = styled.section`
	position: relative;
	background-color: #365277;
	padding: 130px 0 142px;
	${media.maxSm`
		padding: 60px 0 50px;
	`}
	.container {
		text-align: center;
		max-width: 1585px;
	}
	${H3} {
		color: ${colors.white};
		font-family: ${fonts.robotoMedium};
		font-size: 34px;
		line-height: 32px;
		text-align: center;
	}
	${P} {
		margin-top: 30px;
		font-family: ${fonts.openSans};
		font-size: 15px;
		line-height: 30px;
		color: ${colors.white};
		text-align: left;
	}
	.content-section-top-border {
		width: 100%;
		position: absolute;
		top: -32px;
		left: 0;
	}
	.details-cover {
		padding-top: 202px;
		${media.laptopM`
			padding-top: 100px;
		`}
		${media.maxSm`
			padding-top: 0;
		`}
		.container {
			max-width: 1320px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			${media.maxSm`
				flex-wrap: wrap;
		`}
		}
	}
	.question-mark {
		width: 30%;
		max-width: 338px;
		height: auto;
		${media.tablet`
			width: 25%;
		`}
		${media.maxSm`
			display: none;
		`}
	}
`;
const WhatIsBetter = () => (
	<StyledWhatIsBetter>
		<ContentSectionTopBorder />
		<Container>
			<H3 as={'h2'}>What’s Better – Native or Hybrid Applications?</H3>
			<P>
				You never get a second chance to make a good first impression. This is
				certainly the case with apps. First impressions mean everything.
				Usually, mobile app users are more forgiving at first. Studies show that
				nearly 80% of consumers will retry a mobile app if it fails once or
				possibly twice. However, only about 15% will give it two more attempts
				to see if it works. What’s the takeaway? Make sure your app works.
			</P>
			<br />
			<br />
			<P>
				The best way to do it is to create a hybrid app that is compatible for
				all operating systems – both desktop and mobile. Unlike native apps,
				which are developed specifically for a particular operating system,
				hybrid apps (or multi-platform applications) are adaptable, based on the
				device you’re using. <b>PixelTeh</b> builds them using a combination of
				technologies, such as HTML, CSS, and JavaScript.
			</P>
		</Container>
		<div className="details-cover">
			<Container>
				<DetailsItem title="NATIVE APPS" images={iconsFirst}>
					Developed for a specific platform, such as iOS, Android, or the web,
					native apps are built with the operating system’s own components and
					tools. An
					<Link to={'/technologies-android'}> Android app&&&</Link> is coded in
					Java, and
					<Link to={'/technologies-ios'}> iPhone app</Link> is usually created
					in either Swift or Objective C.
				</DetailsItem>
				<img
					className="question-mark"
					src={question}
					alt="blue question mark"
				/>
				<DetailsItem title="HYBRID APPS" images={iconsSecond}>
					A hybrid app combines the elements of native and web applications. It
					is packaged within a native app shell. So, its performance is the same
					as a web app, but it can run on any mobile phone, taking advantage of
					hardware like camera, contact, GPS, and more.
				</DetailsItem>
			</Container>
		</div>
	</StyledWhatIsBetter>
);
export default WhatIsBetter;
