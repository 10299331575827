import React from 'react';
import PropTypes from 'prop-types';

import AnimatedOnScroll from './AnimatedOnScroll';
import CenterButton from './CenterButton';
const CenterAnimatedButton = ({
	linkTo,
	animateIn,
	animateOut,
	children,
	gaLabel,
}) => (
	<AnimatedOnScroll animateIn={animateIn} animateOut={animateOut}>
		<CenterButton gaLabel={gaLabel} linkTo={linkTo}>
			{children}
		</CenterButton>
	</AnimatedOnScroll>
);

CenterAnimatedButton.propTypes = {
	animateIn: PropTypes.string,
	animateOut: PropTypes.string,
	linkTo: PropTypes.string,
	gaLabel: PropTypes.string.isRequired,
	children: PropTypes.any,
};

export default CenterAnimatedButton;
