import React from 'react';
import { node, object, shape, string } from 'prop-types';
import SEO from '../components/SEO';
import Navigation from '../components/Navigation';

import GlobalStyle from '../globalStyles';
import Footer from '../components/Footer';
import usePreloader from '../hooks/use-preloader';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'sanitize.css';
import CookiesBanner from '../components/CookieBanner';

const PageLayout = ({
	children,
	location,
	location: { pathname },
	seoConfig,
}) => {
	if (pathname !== '/') {
		usePreloader();
	}
	return (
		<>
			<SEO {...seoConfig} location={location} />
			<GlobalStyle />
			<CookiesBanner />
			<Navigation pathname={pathname} />

			{children}

			<Footer />
		</>
	);
};

PageLayout.propTypes = {
	children: node,
	location: shape({
		pathname: string.isRequired,
	}).isRequired,
	seoConfig: object.isRequired,
};

export default PageLayout;
