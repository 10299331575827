export const FrameworksData = [
	{
		text:
			'Strong, robust, and flexible. That’s what PhoneGap is all about. ' +
			'For basic apps that look great and function well, this framework is ' +
			'perfect. It offers excellent flexibility for coders. It’s' +
			' compatible for all platforms. PhoneGap works on HTML5, CSS3, ' +
			'and JavaScript.',
	},
	{
		text:
			'One source for all platforms, Android and iOS! Developing a ' +
			'hybrid app takes less time, resources, and effort with Ionic. The ' +
			'framework produces a unified look and feel. We use Ionic to build ' +
			'premium applications quickly and deploy standard tools with one ' +
			'code base.',
	},
	{
		text:
			'Xamarin makes your customized app look and feel native – even ' +
			'though it is a multi-platform builder. By using this framework, an ' +
			'app maintains its native user interface controls. So, it not only ' +
			'looks like it is part of the operating system, it behaves that way too.',
	},
	{
		text:
			'When it comes to UI and UX (User Interface and User Experience), ' +
			'Kony is a powerful one-stop shop for cross- platform application ' +
			'development. We are able to build a custom app for all ' +
			'platforms in an efficient manner. Backend integration is excellent ' +
			'as well.',
	},
	{
		text:
			'Speed, customization, and impressive data management. Sencha does ' +
			'it all. <b>PixelTeh</b> uses this framework to rapidly develop an app that ' +
			'works across multiple browsers simultaneously. Our team delivers ' +
			'beautiful applications to the market faster with Sencha.',
	},
	{
		text:
			'The biggest tech giants use React Native to develop the best hybrid ' +
			'apps in the industry. The framework sets itself apart because it ' +
			'produces apps fast while allowing for customization. ' +
			'With React Native, the new app performs like it is a ' +
			'native app.',
	},
];
