import { graphql, useStaticQuery } from 'gatsby';

export const useHeroPictureTechnologies = () => {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "technologies.jpg" }) {
				...ChildImageSharpFluid
			}
		}
	`);
	return data.file.childImageSharp.fluid;
};
