import 'react';
import styled from 'styled-components';
// Styles
import { colors } from '../../globalStyles';

//----------------------------------------------------------------------------------------------------------------------
const backgroundHover = {
	twitter: '#55acee',
	fb: '#3b5998',
	linkedin: '#0e76a8',
	instagram: '#ee529e',
};

const SocialButton = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	background-color: ${colors.orange};
	transition: background-color 0.3s;
	&:hover {
		cursor: pointer;
		background-color: ${({ type }) => backgroundHover[type]};
	}
	img {
		width: 55%;
	}
`;

export default SocialButton;
