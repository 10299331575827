import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

function SEO({ description, lang, keywords, title, location }) {
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			// titleTemplate={`%s | ${data.site.siteMetadata.title}`}
			meta={[
				{
					name: 'description',
					content: description,
				},
				{
					property: 'og:title',
					content: title,
				},
				{
					property: 'og:description',
					content: description,
				},
				{
					property: 'og:image',
					content: 'https://pixelteh.com/logo.png',
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: 'PixelTeh',
				},
				{
					name: 'twitter:title',
					content: title,
				},
				{
					name: 'twitter:description',
					content: description,
				},
				{
					name: 'keywords',
					content: keywords,
				},
			]}
		>
			<link
				rel="canonical"
				key={location.href}
				href={location.protocol +'//www.' + location.hostname + `${location.pathname==='/' ? '/' : `${location.pathname}${location.pathname && (location.pathname.lastIndexOf('/')=== location.pathname.length-1) ? '' : '/'}`}`}/>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: 'en',
	keywords: '',
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	keywords: PropTypes.string,
	// keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
	location: PropTypes.object,
};

export default SEO;
