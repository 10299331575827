import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import H1 from '../_common/H1';
// import H2 from '../_common/H2';
import { colors, fontSizes, fonts, media } from '../../globalStyles';
import AnimatedOnScroll from '../_common/AnimatedOnScroll';

const StyledSectionHeaderBig = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding-top: 116px;
	margin-bottom: 78px;

	${H1} {
		font-size: ${fontSizes.h1};
		color: ${colors.blackTitle};
		font-family: ${fonts.loveloBlack};
		margin-bottom: 41px;
		text-align: center;
		span {
			font-size: ${fontSizes.h4};
			color: ${colors.darkGrey};
			font-family: ${fonts.loveloBlack};
			text-align: center;
			padding-bottom: 41px;
			&:after {
				content: '';
				height: 2px;
				background-color: ${colors.orange};
				transform: translateX(-50%);
				width: 200px;
				position: absolute;
				top: auto;
				right: auto;
				bottom: -1px;
				left: 50%;
			}
		}
	}
	${media.tablet`
		${H1}{
			font-size: 40px;
			line-height: 1.2;
		}
	`}
	${media.maxSm`
		${H1}{
			font-size: 38px;
			line-height: 40px;
		}
	`}
`;

const SectionHeaderBig = props => (
	<StyledSectionHeaderBig>
		<AnimatedOnScroll>
			<H1>
				{props.title} <br />
				<span>{props.subtitle}</span>
			</H1>
		</AnimatedOnScroll>
		<AnimatedOnScroll />
	</StyledSectionHeaderBig>
);

SectionHeaderBig.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.any,
};
export default SectionHeaderBig;
