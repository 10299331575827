import React from 'react';
import PropTypes from 'prop-types';

import StyledTextContainer from '../_common/TextContainer';
import CenterAnimatedButton from '../_common/CenterAnimatedButton';
import ContentSection from '../ContentSection';

const PageDescription = ({
	children,
	title,
	subtitle,
	bgColor,
	showBottomBtn,
	headerType,
	textPaddingBottom,
	paddingBottom,
	linkTo,
	seoTitle,
	seoSubTitle,
}) => (
	<ContentSection
		bottomLine
		title={title}
		subtitle={subtitle}
		bgColor={bgColor}
		headerType={headerType}
		paddingBottom={paddingBottom}
		seoTitle={seoTitle}
		seoSubTitle={seoSubTitle}
	>
		<StyledTextContainer paddingBottom={textPaddingBottom}>
			{children}
		</StyledTextContainer>
		{showBottomBtn && (
			<CenterAnimatedButton gaLabel="home-page Discover More" linkTo={linkTo} />
		)}
	</ContentSection>
);

PageDescription.propTypes = {
	children: PropTypes.any,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	headerType: PropTypes.oneOf(['big', 'normal']),
	bgColor: PropTypes.string,
	showBottomBtn: PropTypes.bool,
	textPaddingBottom: PropTypes.number,
	paddingBottom: PropTypes.number,
	linkTo: PropTypes.string,
	seoTitle: PropTypes.string,
	seoSubTitle: PropTypes.string,
};

PageDescription.defaultProps = {
	bgColor: 'whiteGrey',
	textPaddingBottom: 53,
	paddingBottom: 0,
};

export default PageDescription;
