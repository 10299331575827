import { css } from 'styled-components';
import { colors, fonts, fontSizes } from '../../../globalStyles';

import arrowIcon from '../../../assets/img/arrow-sprite.png';

const commonButtonStyles = css`
	background-color: ${colors.orange};
	padding: 18px 18px 13px 18px;
	color: ${colors.white};
	font-family: ${fonts.loveloBlack};
	font-size: ${fontSizes.p};
	transition: all 0.3s;
	border: 2px solid transparent;
	text-transform: uppercase;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	text-decoration: none !important;
	span {
		padding-top: 3px;
	}
	&:hover {
		color: ${colors.orange} !important;
		background-color: ${colors.white};
		border: 2px solid ${colors.orange};
		text-decoration: none;
	}
	${({ arrow }) =>
		arrow &&
		css`
			&:after {
				content: '';
				width: 23px;
				height: 11px;
				margin: auto 0 4px 12px;
				background: url(${arrowIcon}) -10px -10px;
				display: inline-block;
			}
			&:hover {
				&:after {
					background: url(${arrowIcon}) -53px -10px;
				}
			}
		`}
	${({ clear }) =>
		clear &&
		css`
			&,
			&:hover {
				background: transparent;
				border: none;
				color: #575757;
			}
			&:after {
				background: url(${arrowIcon}) -53px -10px;
			}
		`}
`;

export default commonButtonStyles;
