import styled from 'styled-components';
import { fonts, fontSizes, colors } from '../../globalStyles';

const P = styled.p`
	font-family: ${fonts.openSans};
	font-size: ${fontSizes.p};
	margin: 0;
	line-height: 1;
	color: ${colors.blackTitle};
`;

export default P;
