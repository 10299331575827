import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import H1 from '../_common/H1';
import H2 from '../_common/H2';
import { colors, fonts, fontSizes, media } from '../../globalStyles';
import AnimatedOnScroll from '../_common/AnimatedOnScroll';

export const StyledSectionHeader = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	padding-top: 86px;
	padding-bottom: 80px;
	flex-direction: column;
	${H1} {
		font-size: 40px;
		color: ${colors.blackTitle};
		font-family: ${fonts.robotoRegular};
		text-align: center;
		margin-bottom: 17px;
		font-weight: 700;
		span {
			margin-left: 8px;
			color: ${colors.orange};
		}
	}
	${H2} {
		max-width: 700px;
		margin: 0 auto;
		font-size: ${fontSizes.h5};
		color: ${colors.greyItalicTitle};
		font-family: ${fonts.robotoLightItalic};
		text-align: center;
		line-height: 2;
		position: relative;
		${({ bottomLine }) =>
			bottomLine &&
			css`
				&:after {
					content: '';
					height: 2px;
					background-color: ${colors.orange};
					transform: translateX(-50%);
					width: 120px;
					position: absolute;
					top: auto;
					right: auto;
					bottom: -41px;
					left: 50%;
					${media.maxSm`
						bottom: -27px;
					`}
				}
			`}
	}
	${media.maxSm`
		padding-top: 48px;
		padding-bottom: 50px;
		${H1}{
			font-size: 36px;
			line-height: 42px;
		}
	`}
	${media.maxXs`
		${H1}{
			font-size: 25px;
			line-height: 36px;
		}
	`}
`;

const SectionHeader = props => (
	<StyledSectionHeader bottomLine={props.bottomLine}>
		<AnimatedOnScroll>
			<H1
				as={props.seoTitle}
				dangerouslySetInnerHTML={{ __html: props.title }}
			/>
		</AnimatedOnScroll>
		{props.subtitle && (
			<AnimatedOnScroll>
				<H2
					as={props.seoSubTitle}
					dangerouslySetInnerHTML={{ __html: props.subtitle }}
				/>
			</AnimatedOnScroll>
		)}
	</StyledSectionHeader>
);

SectionHeader.propTypes = {
	title: PropTypes.any,
	subtitle: PropTypes.any,
	bottomLine: PropTypes.bool,
	seoTitle: PropTypes.string,
	seoSubTitle: PropTypes.string,
};

export default SectionHeader;
