import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyles';

const Wrapper = styled.div`
	text-align: left;
	font-size: 15px;
	line-height: 30px;
	padding-left: 7vw;
	margin-top: 10px;
	${media.maxSm`
		padding-left: 0;
		text-align: center;
	`}
`;

export const RightsRow = () => (
	<Wrapper>
		{new Date().getFullYear()} &copy; <b>PIXELTEH</b> - All rights reserved
	</Wrapper>
);
