import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyles';

import Container from '../_common/Container';
import Row from '../_common/Row';
import Col from '../_common/Col';

import { LogoRow } from './LogoRow';
import { NavRow } from './NavRow';
import { RightsRow } from './RightsRow';

import borderImg from '../../assets/img/border-footer.png';
import { colors } from '../../globalStyles';

const StyledFooter = styled.footer`
	background-color: rgba(241, 241, 241, 0.6);

	.container-fluid {
		max-width: 1670px;
		padding-top: 74px;
		${media.maxSm`
    	padding-top: 47px;
    	padding-left: 0;
    	padding-right: 0;
    `}
	}

	.logoRow {
		${media.maxSm`text-align: center;`}
	}

	hr {
		//margin-left: -15px;
		//margin-right: -15px;
		border-color: ${colors.hrGrey};
		border-width: 1px 0 0 0;
		${media.maxSm`
			width: 155px;
			margin: 30px auto 0;
		`}
	}

	:before,
	:after {
		content: ' ';
		display: block;
		background-image: url(${borderImg});
		background-repeat: repeat-x;
		width: 100%;
		height: 2rem;
	}
`;

const Footer = () => (
	<StyledFooter>
		<Container fluid>
			<Row
				noGutters
				className="align-items-center flex-column flex-md-row logoRow"
			>
				<Col lg={4} md={4} sm={4} xs={12}>
					<LogoRow />
				</Col>

				<Col lg={8} md={8} sm={8} xs={12}>
					<RightsRow />
				</Col>

				<Col xs={12}>
					<hr />
				</Col>
			</Row>

			<NavRow />
		</Container>
	</StyledFooter>
);

export default Footer;
