import PropTypes from 'prop-types';
import styled from 'styled-components';
import commonButtonStyles from './commonStyles/commonButtonStyles';

const StyledButton = styled.button`
	${commonButtonStyles}
`;

StyledButton.propTypes = {
	arrow: PropTypes.bool,
	clear: PropTypes.bool,
};

export default StyledButton;
