import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ContentSection from '../ContentSection/index';
import H1 from '../_common/H1';

import { media } from '../../globalStyles';

const StyledStack = styled.div`
	margin: auto;
	max-width: ${({ fluid }) => (fluid ? '100%' : '1100px')};
	padding-bottom: 100px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	${media.maxSm`
		padding-bottom: 40px;
	`}
	.stack-image-cover {
		width: 23%;
		max-width: 238px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #f7f7f7;
		border: 1px solid rgba(125, 125, 125, 0.35);
		border-radius: 6px;
		margin-bottom: 29px;
		padding: 26px;
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			${media.tablet`
				max-height: 91px;
			`}
		}
		${media.tablet`
			padding: 10px;
		`}
		${media.phone`
			width: 45%;
		`}
	}
	${H1} {
		margin: 0;
	}
	${({ fluid }) =>
		fluid &&
		css`
			flex-wrap: wrap;
			.stack-image-cover {
				width: 18%;
				height: 148px;
			}
			${media.laptop`
			justify-content: center;
			.stack-image-cover{
				margin: 0 13px 20px;
				width: 23%;
			}
		`}
			${media.maxSm`
			.stack-image-cover{
				width: 46%;
			}
		`}
		`}
`;

const Stack = ({ title, fluid, images }) => (
	<ContentSection noTopBorder paddingBottom={0} title={title} seoTitle={'h2'}>
		<StyledStack fluid={fluid}>
			{images.map((item, index) => (
				<div key={index} className="stack-image-cover">
					<img src={item.icon} alt={item.alt} />
				</div>
			))}
		</StyledStack>
	</ContentSection>
);

Stack.propTypes = {
	title: PropTypes.string,
	images: PropTypes.array,
	fluid: PropTypes.bool,
};
Stack.defaultProps = {
	fluid: false,
};

export default Stack;
