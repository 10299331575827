import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../globalStyles';
import DropdownItem from './DropdownItem';

const links = [
	{ link: '/technologies', title: 'Technologies overview' },
	{ link: '/technologies-full-stack', title: 'Full-Stack development' },
	{ link: '/technologies-blockchain', title: 'Blockchain & cryptocurrencies' },
	{ link: '/technologies-machine-learning', title: 'Machine Learning & AI' },
	{ link: '/technologies-crossplatform', title: 'Crossplatform Development' },
	{ link: '/technologies-android', title: 'Android Development' },
	{ link: '/technologies-ios', title: 'Ios Development' },
];

export const StyledTechnologiesDropdown = styled.div`
	position: absolute;
	top: 90px;
	left: 15px;
	min-width: 360px;
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	display: block;
	transition: opacity 0.5s;
	ul {
		background-color: rgba(54, 82, 119, 0.95);
		padding: 0 35px;
		height: 100%;
		${media.maxSm`padding: 0`}
	}
	${media.maxSm`
		position: relative;
		top: 0;
		left: 0;
		max-height: 0;
		overflow: hidden;
		opacity: 1;
		pointer-events: all;
		transition: all 0.5s;
		min-width: 100%;
		&.visible {
			max-height: 300px;
		}
	`}
`;

const TechnologiesDropdown = ({ isVisible, handleOnClick }) => (
	<StyledTechnologiesDropdown className={isVisible ? 'visible' : ''}>
		<ul>
			{links.map((item, i) => (
				<DropdownItem
					inDropdown
					to={item.link}
					key={i}
					handleOnClick={handleOnClick}
				>
					{item.title}
				</DropdownItem>
			))}
		</ul>
	</StyledTechnologiesDropdown>
);

TechnologiesDropdown.propTypes = {
	isVisible: PropTypes.bool,
	handleOnClick: PropTypes.func,
};

export default TechnologiesDropdown;
