import styled, { keyframes } from 'styled-components';
import { colors } from '../../globalStyles';

const blinkCircle = keyframes`
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const PulseCircle = styled.span`
	&:before {
		display: inline-block;
		border: 3px solid ${colors.lightGrey};
		border-radius: 100%;
		width: 35px;
		height: 35px;
		position: absolute;
		left: -11px;
		top: -11px;
		content: '';
		animation-direction: normal;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-name: ${blinkCircle};
		animation-timing-function: ease-in-out;
	}
	width: 13px;
	height: 13px;
	background-color: ${colors.orange};
	border-radius: 100%;
	position: relative;
	display: inline-block;
`;

export default PulseCircle;
