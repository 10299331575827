import React from 'react';
import styled from 'styled-components';

import pixeltehTitle from '../../assets/img/pixelteh-title.png';
import orangeSquares from '../../assets/img/orange-squares.png';

const Wrapper = styled.div`
	.pixelteh-title {
		margin-right: 0.5rem;
	}

	.orange-squares {
		vertical-align: top;
	}
`;

export const LogoRow = () => (
	<Wrapper>
		<img alt="PIXELTEH Title" className="pixelteh-title" src={pixeltehTitle} />

		<img alt="orange squares" className="orange-squares" src={orangeSquares} />
	</Wrapper>
);
