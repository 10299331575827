import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledCoverImage = styled(Img)`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	//z-index: -1;
`;

const CoverImg = ({ fluid, title }) => (
	<StyledCoverImage
		fluid={fluid}
		title={title}
		alt={title}
		style={{ position: 'absolute' }}
	/>
);

CoverImg.propTypes = {
	fluid: PropTypes.object,
	title: PropTypes.string,
};

export default CoverImg;
