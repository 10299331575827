import React from 'react';
import { shape, string } from 'prop-types';
import PageLayout from '../layouts/PageLayout';
import SubpageTopCover from '../components/SubpageTopCover';
import DescriptionBlock from '../components/TechnologiesCrossPlatform/PageDescription';
import Typography from '../components/TechnologiesCrossPlatform/Typography';
import WhatIsBetter from '../components/TechnologiesCrossPlatform/WhatIsBetter';
import Frameworks from '../components/TechnologiesCrossPlatform/Frameworks';
import SubmitQuote from '../components/SubmitQuote/index';
import Performance from '../components/TechnologiesCrossPlatform/Performance';
import Stack from '../components/Stack';
import java from '../assets/img/java-icon.png';
import phonegap from '../assets/img/phonegap-icon.png';
import js from '../assets/img/js-icon.png';
import sencha from '../assets/img/sencha-icon.png';
import html from '../assets/img/html-icon.png';
import jquery from '../assets/img/jquery-icon.png';
import ionic from '../assets/img/ionic-icon.png';
import css from '../assets/img/css-icon.png';
import { useHeroPictureTechnologies } from '../hooks/use-hero-picture-technologies';
import { crossPlatform } from '../seoConfig';

const stackImages = [
	{
		icon: java,
		alt: 'gray java logo',
	},
	{
		icon: phonegap,
		alt: 'gray phone gap logo',
	},
	{
		icon: js,
		alt: 'gray java script logo',
	},
	{
		icon: sencha,
		alt: 'gray sencha logo',
	},
	{
		icon: html,
		alt: 'gray html 5 logo',
	},
	{
		icon: jquery,
		alt: 'gray jQuery logo',
	},
	{
		icon: ionic,
		alt: 'gray ionic logo',
	},
	{
		icon: css,
		alt: 'gray CSS logo',
	},
];
const TechnologiesCrossplatform = ({ location }) => {
	const img = useHeroPictureTechnologies();
	return (
		<PageLayout
			location={location}
			seoConfig={crossPlatform}
			altText={'pixelteh team with computers'}
		>
			<SubpageTopCover backgroundImg={img}>
				Cross-Platform App Development
			</SubpageTopCover>
			<DescriptionBlock />
			<Typography />
			<WhatIsBetter />
			<Frameworks />
			<SubmitQuote />
			<Performance />
			<Stack title="Hybrid Apps Technology Stack" images={stackImages} />
		</PageLayout>
	);
};
TechnologiesCrossplatform.propTypes = {
	location: shape({
		pathname: string.isRequired,
	}).isRequired,
};
export default TechnologiesCrossplatform;
