import React from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';

import Button from './_common/Button';
import { colors } from '../globalStyles';

const StyledCookiesBanner = styled.div`
	.cookieConsent {
		box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
	}
	a {
		color: ${colors.orange};
	}
`;

const CookiesBanner = () => (
	<StyledCookiesBanner>
		<CookieConsent
			disableButtonStyles
			location="bottom"
			buttonText="I agree"
			cookieName="pixelteh"
			style={{ background: '#365277', zIndex: 98 }}
			buttonStyle={{ color: '#fff', fontSize: '13px' }}
			ButtonComponent={Button}
			expires={150}
		>
			We use cookies to ensure your best experience on our website. To get
			details please see our <Link to="/cookies-policy">Cookie Policy</Link>.
		</CookieConsent>
	</StyledCookiesBanner>
);

export default CookiesBanner;
