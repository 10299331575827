import { useEffect } from 'react';

const usePreloader = () =>
	useEffect(() => {
		const body = document.querySelector('body');
		body.classList.remove('show-preloader');
		return () => {
			body.classList.add('show-preloader');
		};
	});

export default usePreloader;
