import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import P from './P';

const StyledTextContainer = styled.div`
	display: block;
	margin: 0 auto;
	max-width: 1511px;
	text-align: ${({ textAlign }) => textAlign || 'center'};
	${P} {
		text-align: ${({ textAlign }) => textAlign || 'center'};
		line-height: 2;
		margin-bottom: 10px;
	}
	${({ paddingBottom }) => paddingBottom && css`padding-bottom: ${paddingBottom}px;`}
`;

StyledTextContainer.propTypes = {
	paddingBottom: PropTypes.number,
	textAlign: PropTypes.string,
};

export default StyledTextContainer;
