import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NavbarNav from './NavbarNav';
import NavigationLink from './NavigationLink';
import NavbarToggler from './NavbarToggler';

import logo from '../../assets/img/logo.png';
import { media } from '../../globalStyles';
import TechnologiesDropdown from './TechnologiesDropdown';

const StyledNavbar = styled.nav`
	z-index: 98;
	max-width: 1585px;
	margin: 0 auto;
	flex-flow: row nowrap;
	justify-content: flex-start;
	display: flex;
	padding: 59px 15px 3px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	${media.maxMd`
		padding-left: 140px;
		padding-top: 35px;
	`}
`;

const NavbarBrand = styled.a`
	margin: 0;
	position: absolute;
	top: 0;
	right: auto;
	bottom: auto;
	left: 15px;
	width: 160px;
	height: 156px;
	padding: 0;
	text-decoration: none !important;
	img {
		width: 100%;
	}
	${media.maxMd`
		width: 120px;
		height: 117px;
	`}
	${media.maxSm`
		left: 50%;
        margin-left: -60px;
	`}
`;

const links = [
	{
		path: '/',
		label: 'Home',
	},
	{
		path: '/technologies',
		label: 'Technologies',
		withDropdown: true,
	},
	{
		path: '/portfolio',
		label: 'Portfolio',
	},
	{
		path: '/team',
		label: 'The Team',
	},
	// {
	// 	path: '/blog',
	// 	label: 'Blog',
	// },
	{
		path: '/contact',
		label: 'Contact',
	},
];

class Navigation extends React.Component {
	static propTypes = {
		pathname: PropTypes.string,
	};

	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			isFixed: false,
			isVisibleDropdown: false,
		};
	}

	componentDidMount() {
		this.bodyEl = document.querySelector('body');
		this.vh = window.innerHeight;
		window.addEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		const gt = window.scrollY > this.vh;
		const { isFixed } = this.state;
		if (gt && !isFixed) {
			this.setState({ isFixed: true });
		} else if (!gt && isFixed) {
			this.setState({ isFixed: false });
		}
	};

	toggle = () => {
		if (window.innerWidth > 767) {
			return;
		}
		const { isOpen } = this.state;
		const action = isOpen ? 'remove' : 'add';

		this.bodyEl.classList[action]('disable-scroll');
		this.setState({
			isOpen: !isOpen,
			isVisibleDropdown: false,
		});
	};

	handleOnClick = withDropdown => event => {
		if (withDropdown) {
			event.preventDefault();
			this.setState({ isVisibleDropdown: !this.state.isVisibleDropdown });
		} else {
			this.bodyEl.classList.remove('disable-scroll');
		}
	};

	handleOnDropdownLink = () => {
		this.bodyEl.classList.remove('disable-scroll');
	};

	isActive = pathToCompare => {
		const { pathname } = this.props;
		const re = new RegExp(pathToCompare);
		return pathname === '/' || pathToCompare === '/'
			? pathname === pathToCompare
			: re.test(pathname);
	};

	render() {
		const { isOpen, isFixed, isVisibleDropdown } = this.state;

		return (
			<StyledNavbar>
				<NavbarBrand href="/">
					<img src={logo} alt="pixelteh logo" />
				</NavbarBrand>

				<NavbarToggler
					isFixed={isFixed}
					isOpen={isOpen}
					onClick={this.toggle}
				/>

				<NavbarNav isOpen={isOpen}>
					{links.map(({ path, label, withDropdown }) => (
						<NavigationLink
							onClick={this.handleOnClick(withDropdown)}
							key={path}
							to={path}
							isActive={this.isActive(path)}
							dropdown={
								withDropdown && (
									<TechnologiesDropdown
										isVisible={isVisibleDropdown}
										handleOnClick={this.handleOnDropdownLink}
									/>
								)
							}
						>
							{label}
						</NavigationLink>
					))}
				</NavbarNav>
			</StyledNavbar>
		);
	}
}

export default Navigation;
