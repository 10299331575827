import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import classnames from 'classnames';
import PulseCircle from '../_common/PulseCircle';
import { colors, fonts, fontSizes, media } from '../../globalStyles';
import { StyledTechnologiesDropdown } from './TechnologiesDropdown';

export const StyledNavItem = styled.li`
	text-align: center;
	line-height: 1;
	position: relative;
	font-size: ${fontSizes.h5};
	font-family: ${fonts.loveloBlack};

	.nav-link {
		color: #f9f9f9;
		position: relative;
		display: block;
		padding: 69px 15px 8px;
		text-decoration: none !important;

		${media.xxl`
			padding-right: 33px;
    	    padding-left: 33px;
		`}
		${media.maxMd`
			font-size: 14px;
			padding-left: 10px;
			padding-right: 10px;
		`}
	}

	&.has-dropdown .nav-link {
		${media.minSm`pointer-events: none;`}
	}

	${PulseCircle}, .circle {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	${PulseCircle} {
		display: none;
	}

	.circle {
		display: inline-block;
		width: 8px;
		height: 8px;
		background-color: ${colors.lightGrey};
		border-radius: 100%;
	}

	&:before {
		height: 24px;
		max-height: 0;
		transition: max-height 0.8s ease-out;
		width: 1px;
		content: '';
		left: 50%;
		top: 35px;
		position: absolute;
		background-color: ${colors.lightGrey};
	}

	&:hover,
	&.active {
		${PulseCircle} {
			display: inline-block;
		}

		.circle {
			display: none;
		}

		&:before {
			max-height: 24px;
		}
	}
	&:hover {
		&.has-dropdown {
			${StyledTechnologiesDropdown} {
				opacity: 1;
				pointer-events: all;
			}
		}
	}
	${media.maxSm`
		.circle, ${PulseCircle}, &:before{
			display: none!important;
		}
		.nav-link{
			padding: 0;
			line-height: 40px;
		}
	`}
`;

const NavigationLink = ({
	children,
	to,
	isActive,
	onClick,
	hideCircle,
	dropdown,
}) => {
	const cn = classnames({
		active: isActive,
		'has-dropdown': !!dropdown,
	});
	return (
		<StyledNavItem className={cn}>
			{!hideCircle && <PulseCircle />}
			<span className="circle" />
			{dropdown ? (
				<a className="nav-link" href="/" onClick={onClick}>
					{children}
				</a>
			) : (
				<Link className="nav-link" to={to} onClick={onClick}>
					{children}
				</Link>
			)}

			{dropdown}
		</StyledNavItem>
	);
};

NavigationLink.propTypes = {
	children: PropTypes.string,
	to: PropTypes.string,
	isActive: PropTypes.bool,
	hideCircle: PropTypes.bool,
	onClick: PropTypes.func,
	dropdown: PropTypes.any,
};

export default NavigationLink;
