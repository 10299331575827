export const performanceData = [
	{
		title: 'Developers',
		count: '55',
	},
	{
		title: 'Happy Clients',
		count: '150',
	},
	{
		title: 'Repeat Clients',
		count: '75',
	},
	{
		title: 'Apps Delivered',
		count: '400',
	},
];
