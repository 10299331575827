import React from 'react';
import PropTypes from 'prop-types';
import H3 from '../../_common/H3';
import styled from 'styled-components';
import { fonts, colors, media } from '../../../globalStyles';
import AnimatedOnScroll from './../../../components/_common/AnimatedOnScroll';

const StyledTypographyItem = styled.div`
	position: relative;
	text-align: center;
	max-width: 42%;
	${media.phone`
		order:1
		width: 28%
	`}
	.typography-sub-cover {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		z-index: 2;
	}
	${H3} {
		font-family: ${fonts.openSansSembiBold};
		color: ${colors.blackTitle};
		font-size: 15px;
	}

	.typography-image {
		max-height: 100%;
		width: auto;
		max-width: 100%;
		margin: 10px 0;
		${media.phone`
			max-width:100%;
			height: 92px;
		`}
	}
	.show-round-bg {
		position: relative;
		&:before {
			display: block;
			content: '';
			width: 340px;
			height: 340px;
			box-shadow: 0 0 49px rgba(0, 0, 0, 0.13);
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;
			${media.maxSm`
				max-width: 240px;
				max-height: 240px;
			`}
		}
		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 206px;
			height: 206px;
			background-color: rgba(244, 244, 244, 0.4);
			box-shadow: 0 0 21px rgba(0, 0, 0, 0.13);
			border-radius: 50%;
			z-index: -1;
			${media.maxSm`
				max-width: 150px;
				max-height: 150px;
			`}
		}
	}

	&:nth-child(2) {
		margin-top: 56px;
		${media.maxSm`
			margin-top: 0;
		`}
		${media.phone`
			order: 0;
    	width: 100%;
    	max-width: none;
    	margin-bottom: 60px;
		`}
	}
	&:nth-child(3) {
		margin-top: 100px;
		${media.maxSm`
			margin-top: 0;
		`}
		${media.phone`
			order: 3;
		`}
	}
	&:nth-child(4) {
		margin-top: 18px;
		max-width: none;
		width: 100%;
		${media.maxSm`
			margin-top: 80px;
		`}
		${media.phone`
			order: 2;
			margin-top: 0;
			width: inherit;
		`}
	}
	.add-lines {
		max-height: 200px;
		height: 20vw;
		width: 1px;
		position: relative;
		content: '';
		border-left: 1px dashed ${colors.greyItalicTitle};
		${media.tablet`
			max-height:	106px;
		`}
		${media.maxSm`
			display:none;
		`}
		&:before,
		&:after {
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 10px;
			height: 9px;
			background-color: #c9c9c9;
			content: '';
			display: inline-block;
			position: absolute;
		}
		&:after {
			top: auto;
			bottom: 0;
		}
	}
	.reverse-item {
		display: flex;
		flex-direction: column-reverse;
	}
	.horisontal {
		flex-direction: row;
		.add-lines {
			height: 1px;
			max-width: 190px;
			width: 20vw;
			border-bottom: 1px dashed ${colors.greyItalicTitle};
			margin: 0 60px;
			${media.laptopS`
				margin: 0 50px
			`}
			&:before,
			&:after {
				transform: translateY(-50%);
				top: -50%;
				bottom: auto;
			}
			&:before {
				right: auto;
				left: -2px;
			}
			&:after {
				left: auto;
				right: -2px;
			}
		}
	}
	.line-reverse {
		flex-direction: row-reverse;
	}
`;

const TypographyItem = ({
	img,
	title,
	reverse,
	addLine,
	roundBg,
	lineHorisontal,
	animatedIn,
	animatedOut,
	reverseLine,
	alt,
}) => (
	<StyledTypographyItem>
		<AnimatedOnScroll
			animateIn={`fadeIn${animatedIn}`}
			animateOut={`fadeOut${animatedOut}`}
		>
			<div
				className={`typography-sub-cover
				${roundBg ? 'show-round-bg' : ''}
				${lineHorisontal ? 'horisontal' : ''}
				${reverseLine ? 'line-reverse' : ''}`}
			>
				{addLine && <div className={'add-lines   '} />}
				<div className={`${reverse ? 'reverse-item' : ''}`}>
					<img className="typography-image" src={img} alt={alt} />
					<H3>{title}</H3>
				</div>
			</div>
		</AnimatedOnScroll>
	</StyledTypographyItem>
);

TypographyItem.propTypes = {
	img: PropTypes.any,
	title: PropTypes.string,
	reverse: PropTypes.bool,
	addLine: PropTypes.bool,
	roundBg: PropTypes.bool,
	lineHorisontal: PropTypes.bool,
	animatedIn: PropTypes.string,
	animatedOut: PropTypes.string,
	reverseLine: PropTypes.bool,
	alt: PropTypes.any,
};

TypographyItem.defaultProps = {
	addLine: false,
	lineHorisontal: false,
	reverseLine: false,
};
export default TypographyItem;
