import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyles';
import { Link } from 'gatsby';

import Row from '../_common/Row';
import Col from '../_common/Col';
import P from '../_common/P';
import SocialButton from './SocialButton';

import borderImg from '../../assets/img/border-footer.png';
import facebookIcon from '../../assets/img/facebook-icon.png';
import instagramIcon from '../../assets/img/instagram-icon.png';
import linkedinIcon from '../../assets/img/linkedin-icon.png';
import twitterIcon from '../../assets/img/twitter-icon.png';
import { SOCIALS } from '../../socialsConfig';
import StyledButtonLink from '../_common/ButtonLink';

const navLinks = [
	{
		title: 'Home',
		href: '/',
	},
	{
		title: 'Technologies',
		href: '/technologies',
	},
	{
		title: 'Portfolio',
		href: '/portfolio',
	},
	{
		title: 'Contact',
		href: '/contact',
	},
];

const socialLinks = [
	{
		href: SOCIALS.twitterUrl,
		icon: twitterIcon,
		type: 'twitter',
	},
	{
		href: SOCIALS.facebookUrl,
		icon: facebookIcon,
		type: 'fb',
	},
	{
		href: SOCIALS.linkedinUrl,
		icon: linkedinIcon,
		type: 'linkedin',
	},
	{
		href: SOCIALS.instagramUrl,
		icon: instagramIcon,
		type: 'instagram',
	},
];

const Navlink = styled(Link)`
	display: inline-block;
	padding: 0 10px;
	margin-top: 1rem;
	&:first-child {
		padding-left: 0;
	}
`;

const Wrapper = styled.div`
	padding-top: 50px;
	padding-bottom: 85px;
	${media.maxSm`
		padding-top: 0;
		padding-bottom: 30px;
	`}

	& > div {
		${media.maxMd`
			flex-direction: column;
			align-items: center;
		`}
	}

	.navLinkRow {
		a {
			color: #000;
			text-decoration: underline;
			white-space: nowrap;

			:hover {
				text-decoration: underline;
			}
		}
		${media.maxMd`
			 justify-content: center;
			 margin-bottom: 30px;
		`}
		${media.maxSm`display: none`}
	}

	.SocialRow {
		${media.maxMd`justify-content: center;`}
	}

	.divider {
		height: 30px;
		margin: 30px 0;
		background: url(${borderImg}) repeat-x;
	}

	${SocialButton} {
		margin-left: 9px;
		margin-right: 9px;

		@media (min-width: 768px) {
			margin-top: 10px;
		}
	}
	.get-started-block {
		align-items: center;
		justify-content: space-between;
		@media (max-width: 1440px) {
			flex-direction: column;
			margin-top: -40px;
		}
		${media.maxMd`margin-top: 0;`}
		${StyledButtonLink} {
			border-radius: 50px;
			padding: 15px 32px 12px;
			@media (max-width: 1440px) {
				margin-top: 50px;
			}
			${media.maxSm`margin-top: 30px;`}
			&:hover {
				cursor: pointer;
			}
		}
		${P} {
			@media (max-width: 1440px) {
				margin-top: 20px;
			}
			${media.maxMd`margin-top: 40px;`}
			${media.maxSm`margin-top: 30px;`}
		}
	}
`;

export const NavRow = () => (
	<Wrapper>
		<Row noGutters>
			<Col md={12} lg={5} className="d-none d-md-block">
				<Row noGutters className="navLinkRow">
					{navLinks.map(({ title, href }) => (
						<Col key={title} xs="auto">
							<Navlink to={href}>{title}</Navlink>
						</Col>
					))}
				</Row>
			</Col>

			<Col xs={{ size: 12, order: 'last' }} md={{ size: 12, order: 0 }} lg={3}>
				<div className="d-md-none divider" />

				<Row noGutters className="SocialRow">
					{socialLinks.map(({ type, href, icon }) => (
						<Col key={type} xs="auto" className="d-flex">
							<SocialButton
								href={href}
								type={type}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img alt={type} src={icon} />
							</SocialButton>
						</Col>
					))}
				</Row>
			</Col>

			<Col md={12} lg={4}>
				<div className="d-flex get-started-block">
					<P>Let{'\u0027'}s build something good together!</P>

					<StyledButtonLink arrow to="/contact#contact-section">
						<span>Get started</span>
					</StyledButtonLink>
				</div>
			</Col>
		</Row>
	</Wrapper>
);
