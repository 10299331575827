import React from 'react';
import P from '../_common/P';
import PageDescription from '../PageDescription';
import styled from 'styled-components';

const StyledDescriptionBlock = styled.div`
	${P} {
		text-align: left;
		margin-top: 30px;
		margin-bottom: 0;
	}
`;

const DescriptionBlock = () => (
	<StyledDescriptionBlock>
		<PageDescription
			title="Boost User Engagement with High-Quality & Feature-Rich <strong>Cross-Platform Apps</strong>"
			subtitle="Access Your App on Any Device"
			bgColor="white"
			textPaddingBottom={0}
		/>
	</StyledDescriptionBlock>
);

export default DescriptionBlock;
