import styled from 'styled-components';
import { fontSizes } from '../../globalStyles';
import commonHeadingStyles from './commonStyles/commonHeadingStyles';

const H3 = styled.h3`
	font-size: ${fontSizes.h3};
	${commonHeadingStyles};
`;

export default H3;
