import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, media } from '../../globalStyles';

const StyledNavbarToggler = styled.button`
	position: relative;
	width: 64px;
	height: 60px;
	padding: 20px 17px;
	background: none;
	border: none;
	outline: unset;
	display: inline-block;
	.hamburger {
		display: block;
		margin: 0;
		position: absolute;
		width: 29px;
		left: 17px;
		top: 20px;
	}

	.hamburger-line {
		width: 100%;
		display: block;
		height: 3px;
		background-color: ${colors.white};
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}
	.close {
		display: none;
		position: absolute;
		left: 17px;
		top: 15px;
		margin: 0;
		width: 28px;
		height: 28px;
		&:before,
		&:after {
			content: '';
			background: #fff;
			border-radius: 4px;
			display: block;
			width: 30px;
			height: 4px;
			position: absolute;
			top: 50%;
			right: auto;
			bottom: auto;
			left: 50%;
		}
		&:before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
		&:after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
	&.open {
		.hamburger {
			display: none;
		}
		.close {
			display: block;
		}
	}
`;

const StyledNavbarTogglerWrapper = styled.div`
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 101;
	text-align: right;
	background-color: transparent;
	transition: background-color 0.4s;
	${media.maxSm`
		display: block;
	`}
	${({ isFixed }) =>
		isFixed &&
		css`
			position: fixed;
			background-color: rgba(13, 13, 13, 0.7);
			&.open {
				background-color: transparent;
			}
		`}
`;

const NavbarToggler = ({ onClick, isOpen, isFixed }) => {
	const cn = isOpen ? 'open' : '';

	return (
		<StyledNavbarTogglerWrapper className={cn} isFixed={isFixed}>
			<StyledNavbarToggler className={cn} type="button" onClick={onClick}>
				<figure className="hamburger">
					<span className="hamburger-line" />
					<span className="hamburger-line" />
					<span className="hamburger-line" />
				</figure>
				<figure className="close" />
			</StyledNavbarToggler>
		</StyledNavbarTogglerWrapper>
	);
};

NavbarToggler.propTypes = {
	onClick: PropTypes.func,
	children: PropTypes.any,
	isOpen: PropTypes.bool,
	isFixed: PropTypes.bool,
};

export default NavbarToggler;
