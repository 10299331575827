import React from 'react';
import PropTypes from 'prop-types';
import P from '../../_common/P';
import styled from 'styled-components';
import { fonts, colors, media } from '../../../globalStyles';
import border from '../../../assets/img/border-alt.png';

const StyledFrameworkItem = styled.div`
	width: 30%;
	max-width: 440px;
	text-align: center;
	${media.tablet`
		width: 100%;
		max-width: none;
		&:nth-child(n + 2) {
		&:before {
			display: block;
			content: '';
			width: 80%;
			max-width: 282px;
			height: 20px;
			background-image: url(${border});
			background-repeat: repeat;
			margin: 50px auto;
		}
	}
	`}
	${P} {
		&.item-text {
			color: ${colors.greyItalicTitle};
			font-family: ${fonts.robotoLightItalic};
			font-size: 15px;
			line-height: 30px;
			padding-top: 41px;
			padding-bottom: 30px;
			b {
				font-family: ${fonts.robotoRegular};
				font-style: italic;
			}
		}
	}
	.item-image-cover {
		height: 116px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		img {
			max-height: 100%;
			width: auto;
		}
	}
	&:nth-child(n + 4) {
		&:before {
			display: block;
			content: '';
			width: 80%;
			max-width: 282px;
			height: 20px;
			background-image: url(${border});
			background-repeat: repeat;
			margin: 50px auto;
		}
	}
`;

const FrameworkItem = ({ image, text }) => (
	<StyledFrameworkItem>
		<div className="item-image-cover">
			<img className="item-image" src={image.icon} alt={image.alt} />
		</div>
		<P className="item-text" dangerouslySetInnerHTML={{ __html: text }} />
	</StyledFrameworkItem>
);

FrameworkItem.propTypes = {
	image: PropTypes.any,
	text: PropTypes.string,
};
export default FrameworkItem;
