import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Container = ({ fluid, className, children }) => {
	const classes = classNames(
		className,
		fluid ? 'container-fluid' : 'container'
	);
	return <div className={classes}>{children}</div>;
};

Container.propTypes = {
	fluid: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
};
export default Container;
