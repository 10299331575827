import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import H1 from '../_common/H1';
import ScrollForMore, { StyledScrollForMore } from '../ScrollForMore';

import borderImg from '../../assets/img/border-footer.png';
import bg from '../../assets/img/technologies.jpg';

import { colors, fonts, media } from '../../globalStyles';
import CoverImg from '../_common/CoverImg';

const StyledTopCover = styled.div`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
	height: 36.3vw;

	${H1} {
		color: ${colors.lightGrey};
		font-family: ${fonts.robotoMedium};
		text-align: center;
		line-height: 1.4;
		display: block;
		font-size: 2.5vw;
		position: relative;
		z-index: 2;
		${media.maxSm`
			display: none;
		`}
		${media.maxMd`
			font-size: 26px;
		`}
		
		${media.laptopS`
			padding-top: 40px;
		`}
	}

	${StyledScrollForMore} {
		bottom: 30px;

		@media (max-width: 1199.98px) {
			display: none;
		}

		display: block;
	}

	@media (max-width: 575.98px) {
		height: 230px;
	}
	
	.overlay{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		background-color: rgba(54,82,119,0.7);
	}

	${media.maxSm`
		background: #1e5179;

		&:after {
			content: ' ';
			display: block;
			margin-top: auto;
			background-image: url(${borderImg});
			background-repeat: repeat-x;
			width: 100%;
			height: 32px;
		}
	`}
`;

const SubpageTopCover = ({ backgroundImg, children, altText, showOverlay }) => {
	return (
		<StyledTopCover>
			<H1>{children}</H1>
			<CoverImg fluid={backgroundImg} title={altText} />
			{showOverlay && <div className="overlay" />}
			<ScrollForMore />
		</StyledTopCover>
	);
};

SubpageTopCover.propTypes = {
	backgroundImg: PropTypes.object,
	children: PropTypes.any,
	altText: PropTypes.oneOfType([PropTypes.string, PropTypes.undefined]),
	showOverlay: PropTypes.bool
};
SubpageTopCover.defaultProps = {
	backgroundImg: bg,
};

export default SubpageTopCover;
