import styled from 'styled-components';
import border from '../../assets/img/border-alt.png';

const StyledContentSectionTopBorder = styled.div.attrs({
	className: 'content-section-top-border',
})`
	background-image: url(${border});
	background-repeat: repeat-x;
	height: 32px;
`;

export default StyledContentSectionTopBorder;
