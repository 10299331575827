import React from 'react';
import PropTypes from 'prop-types';
import H3 from '../../_common/H3';
import P from '../../_common/P';
import styled from 'styled-components';
import { fonts, colors, media } from '../../../globalStyles';

const StyledDetailsItem = styled.div`
	width: 35%;
	max-width: 440px;
	text-align: center;
	${media.tablet`
		width: 37%;
	`}
	${media.maxSm`
		margin-top: 40px;
		width: 100%;
		max-width: none;
	`}
	.images-row-cover {
		margin: auto;
		display: flex;
		width: 100%;
		max-width: 260px;
		justify-content: space-between;
		.images-row-item {
			height: 69px;
			width: 69px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			border: 1px dotted #36b1e5;
		}
	}
	${H3} {
		&.item-title {
			color: ${colors.white};
			font-family: ${fonts.openSansBold};
			font-size: 60px;
			font-weight: 700;
			line-height: 68px;
			text-transform: uppercase;
			padding: 27px 0 37px 0;
			${media.laptop`
				font-size: 50px;
				line-height: 1.2;
				padding-bottom: 0;
			`}
			${media.tablet`
				font-size: 40px;
			`}
		}
	}
	${P} {
		&.item-text {
			text-align: center;
		}
		a {
			text-decoration: none;
			color: ${colors.blueText};
			font-family: ${fonts.openSansBold};
		}
	}
`;

const DetailsItem = ({ images, title, children }) => (
	<StyledDetailsItem>
		<div className="images-row-cover">
			{images.map((item, index) => (
				<div key={index} className="images-row-item">
					<img className="single-icon" src={item.icon} alt={item.alt} />
				</div>
			))}
		</div>
		<H3 className="item-title">{title}</H3>
		<P className="item-text">{children}</P>
	</StyledDetailsItem>
);

DetailsItem.propTypes = {
	images: PropTypes.array,
	title: PropTypes.string,
	children: PropTypes.string,
};
export default DetailsItem;
