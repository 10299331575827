import { useEffect } from 'react';

const useRequestIdleCallback = callback => {
	useEffect(() => {
		if (!window.requestIdleCallback) {
			callback();
			return;
		}
		window.requestIdleCallback(() => {
			callback();
		});
	}, []);
};

export default useRequestIdleCallback;
