import React, { useState } from 'react';
import styled from 'styled-components';

import Container from '../_common/Container';
import P from '../_common/P';
import { colors, fonts, fontSizes, media } from '../../globalStyles';
import TypingText from './TypingText';
import StyledButtonLink from '../_common/ButtonLink';
import H2 from '../_common/H2';
import AnimatedOnScroll from '../_common/AnimatedOnScroll';
import Particles from '../Particles';
import useRequestIdleCallback from '../../hooks/use-request-idle-callback';

const StyledSubmitQuote = styled.section`
	height: 330px;
	position: relative;
	background-color: #365277;
	padding: 120px 0 85px;
	${media.maxSm`
		height: 278px;
		padding: 106px 0 40px;
	`}
	${media.maxXs`
		padding: 116px 0 40px;
		height: 350px;
	`}
	.quote-block {
		display: flex;
		max-width: 780px;
		justify-content: space-between;
		margin: 0 auto;
		position: relative;
		z-index: 2;
		${media.maxXs`
			flex-direction: column;
			align-items: center;
		`}
		${H2}, .Cursor {
			display: inline-block;
			font-size: ${fontSizes.h2};
			font-family: ${fonts.loveloBlack};
			text-transform: uppercase;
			color: ${colors.blueGrey};
			${media.maxMd`
				font-size: 28px;
			`}
			${media.maxSm`
				font-size: 20px;
				line-height: 1;
			`}
		}
		.Typist {
			padding-top: 30px;
			${media.maxMd`
				text-align: center;
				width: 100%;
			`}
			${media.maxSm`
				padding-top: 20px;
				padding-bottom: 10px;
			`}
		}
		${StyledButtonLink} {
			padding: 45px 32px 43px;
			${media.maxMd`
				width: 106px;
				padding: 40px 20px 36px;
				white-space: nowrap;
			`}
			${media.maxSm`
				padding: 20px 20px 18px;
			`}
			${media.maxXs`
				margin-bottom: 25px;
				margin-top: 10px;
			`}
		}
	}
	${P} {
		font-size: 13px;
		color: #889ebb;
		text-align: center;
		font-family: ${fonts.openSansSembiBoldItalic};
	}

	
`;

const SubmitQuote = () => {
	const [loadParticles, setLoadParticles] = useState(false);
	useRequestIdleCallback(() => {
		setLoadParticles(true);
	});
	return (
		<StyledSubmitQuote>
			<Container>
				<div className="quote-block">
					{loadParticles && <TypingText />}
					<StyledButtonLink to="/contact#contact-section">
						Submit
					</StyledButtonLink>
				</div>
				<AnimatedOnScroll>
					<P>*We Want to Hear from You.</P>
				</AnimatedOnScroll>
			</Container>
			{loadParticles && <Particles />}
		</StyledSubmitQuote>
	);
};
export default SubmitQuote;
