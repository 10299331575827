import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, fonts, fontSizes } from '../../globalStyles';
import { Link } from 'gatsby';

const StyledDropdownItem = styled.li`
	font-size: ${fontSizes.h5};
	font-family: ${fonts.loveloBlack};
	position: relative;
	${media.maxSm`
		font-size: 12px;
	`}
	a {
		color: #f9f9f9;
		text-decoration: none;
		text-align: left;
		display: block;
		line-height: 50px;
		white-space: nowrap;
		${media.maxSm`
			line-height: 40px;
			text-align: center;
		`}
	}
	&:before {
		width: 6px;
		height: 6px;
		background: #fd4326;
		content: '';
		opacity: 0;
		transition: opacity 0.4s;
		display: inline-block;
		position: absolute;
		left: -18px;
		top: 19px;
	}
	&:hover {
		&:before {
			opacity: 1;
		}
	}
`;

const DropdownItem = ({ to, children, handleOnClick }) => (
	<StyledDropdownItem onClick={handleOnClick}>
		<Link to={to}>{children}</Link>
	</StyledDropdownItem>
);

DropdownItem.propTypes = {
	to: PropTypes.string,
	children: PropTypes.any,
	handleOnClick: PropTypes.func,
};

export default DropdownItem;
