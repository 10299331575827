import styled, { css } from 'styled-components';
import { fontSizes } from '../../globalStyles';
import commonHeadingStyles from './commonStyles/commonHeadingStyles';

const H2 = styled.h2`
	font-size: ${fontSizes.h2};
	${commonHeadingStyles};
		${({ mt }) =>
			!!mt &&
			css`
				margin-top: ${mt}px;
			`}
	}
`;

export default H2;
