import React, { useState } from 'react';
import styled from 'styled-components';
import ParticlesJs from 'react-particles-js';
import { media } from '../../globalStyles';
import useRequestIdleCallback from '../../hooks/use-request-idle-callback';

const particlesConfig = {
	particles: {
		number: {
			value: 80,
			density: {
				enable: true,
				value_area: 2800,
			},
		},
		color: {
			value: '#ffffff',
		},
		shape: {
			type: 'circle',
			stroke: {
				width: 0,
				color: '#000000',
			},
			polygon: {
				nb_sides: 1,
			},
		},
		opacity: {
			value: 0.5,
			random: false,
			anim: {
				enable: false,
				speed: 1,
				opacity_min: 0.1,
				sync: false,
			},
		},
		size: {
			value: 1,
			random: true,
			anim: {
				enable: false,
				speed: 60,
				size_min: 0.1,
				sync: false,
			},
		},
		line_linked: {
			enable: true,
			distance: 650,
			color: '#ffffff',
			opacity: 0.1,
			width: 1,
		},
		move: {
			enable: true,
			speed: 5,
			direction: 'none',
			random: false,
			straight: false,
			out_mode: 'out',
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200,
			},
		},
	},
	interactivity: {
		detect_on: 'canvas',
		events: {
			onhover: {
				enable: false,
				mode: 'repulse',
			},
			onclick: {
				enable: true,
				mode: 'push',
			},
			resize: true,
		},
		modes: {
			grab: {
				distance: 800,
				line_linked: {
					opacity: 1,
				},
			},
			bubble: {
				distance: 800,
				size: 80,
				duration: 2,
				opacity: 0.8,
				speed: 3,
			},
			repulse: {
				distance: 400,
				duration: 0.4,
			},
			push: {
				particles_nb: 4,
			},
			remove: {
				particles_nb: 2,
			},
		},
	},
	retina_detect: true,
};

const StyledParticles = styled(ParticlesJs)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 0;
	bottom: 0;
	canvas {
		width: 100%;
		height: 100%;
		z-index: 0;
	}
	${media.maxSm`
		display: none;
	`}
`;
const Particles = () => {
	const [render, setRender] = useState(false);
	useRequestIdleCallback(() => {
		setRender(true);
	});
	return render && <StyledParticles params={particlesConfig} />;
};

export default Particles;
